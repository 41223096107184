/* You can add global styles to this file, and also import other style files */
fa-icon{
  margin: 5px;
  font-size: 20px;
  margin-left: 15px;
}
.h2p {
  color: indigo;
  text-decoration: underline;
  font-size: 16px !important;
  font-weight: normal !important;
}
* {
  box-sizing: border-box;
}
.row {
  margin-right: 0;
  margin-left: 0;
}

body {
  background-color: #e9ecef !important; /* Slightly darker light gray */
  color: #333; /* Default text color */
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;

}
textarea{
  border: solid 1px #343a40;
  border-radius: 5px;
  padding:5px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.3); /* x-offset, y-offset, blur-radius, color */
  outline: none; /* Removes the default focus outline */
}
input {
  border: solid 1px #343a40;
  border-radius: 5px;
  padding:5px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.3); /* x-offset, y-offset, blur-radius, color */
  outline: none; /* Removes the default focus outline */
}

textarea:focus {
  box-shadow: 2px 2px 5px rgba(0,0,0,0.5); /* Darker shadow on focus for better UI feedback */
}
input:focus {
  box-shadow: 2px 2px 5px rgba(0,0,0,0.5); /* Darker shadow on focus for better UI feedback */
}
.border-container {
  border: solid 1px #343a40;
  border-style: dashed;
  padding: 10px;
  border-radius: 5px; /* Optional: for rounded corners */
}
label{
  margin-left: 10px;
}
.ng-select .ng-select-container {
  line-height: 30px;
  border: solid 1px #343a40;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.3);
}
.ng-select .ng-select-container .ng-value-container {
  padding: 2px 5px;
}
.ng-select .ng-select-container:focus-within {
  outline: none;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
}
.ng-select .ng-dropdown-panel {
  border-color: #343a40;
}
.form-group {
  margin-bottom: 15px!important;
}

.form-group label {
  display: block !important;
  margin-bottom: 5px !important;
  font-weight: bold !important;
  color: #555 !important;
}
/* Flexbox for inline radio buttons */
.radio-group {
  display: flex !important;
  gap: 15px !important;
}

.radio-item {
  display: flex !important;
  align-items: center !important;
}

.radio-item label {
  margin-left: 5px !important;
  margin-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.form-group input,
.form-group textarea {
  width: calc(100% - 22px) !important; /* Full width minus padding */
  padding: 10px !important;
  font-size: 16px !important;
  border: 1px solid #ddd !important;
  border-radius: 4px !important;
  transition: border-color 0.6s !important;
  box-shadow: none !important;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none  !important;
}
.unhighlight{
  box-shadow: none !important;
  outline: none  !important;
}
.tableContainer{
  padding: 30px;
  border-radius: 15px;
  background-color: white;
}
.code {
  white-space: pre-wrap; /* Ensures line breaks are respected */
  word-wrap: break-word; /* Ensures long words break and do not overflow */
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 15px;
  font-family: 'Courier New', monospace;
  color: #333;
  max-width: 100%; /* Ensures the content fits within the screen */
  overflow-x: auto; /* Allows horizontal scrolling if needed */
}
.btn-primary {
  color: #fff;
  background-color: #343a40 !important;
  border: 1px solid #ddd !important;
  border-radius: 5px !important;
}
